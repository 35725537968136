import React from "react";
import AugurLogo from "../../../assets/Augur-Logo.png";
import "./AugurDescription.css";
//@ts-ignore
import Fade from "react-reveal/Fade";
import Button from "react-bootstrap/Button";

const AugurDescription: React.FC = () => {
  return (
    <Fade>
      <div className="augur-container">
        <div className="logo">
          <img src={AugurLogo} alt="Augur Logo" width={450} height={267} />
        </div>
        <div className="iframe-container">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/ZHPGdEJzrCE?controls=0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className="augur-description">
          <p>
            Augur is a software designed to collect and analyze structured data
            about free and open source software (FOSS) communities. Augur
            gathers trace data for groups of repositories, normalizes it into a
            designed data model, and provides a variety of metrics about this
            data. These metrics allow for clients to easily view their code
            group's health, such as commits, top committers, line deletions,
            etc., and allows clients to see how their project compares to others
            they are familiar with.
          </p>
          <p>
            Augur is a CHAOSS project, and many of the metrics are
            implementations of the metrics defined by the CHAOSS community.
            Click the "About CHAOSS" button provided for more information.
          </p>
        </div>
        <div className="buttons">
          <Button href="https://github.com/chaoss/augur">View On Github</Button>
          <Button href="http://augur.osshealth.io/" className="button-space">
            Visit Official Site
          </Button>
          <Button href="https://chaoss.community/about/">About CHAOSS</Button>
        </div>
      </div>
    </Fade>
  );
};
export default AugurDescription;
