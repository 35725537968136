import React from "react";
import "./CourseBody.css";

const CourseBody: React.FC = () => {
  return (
    <dl>
      <dd> - Advanced Algorithm Design (I, II, III, IV)</dd>
      <dd> - Calculus (I, II, III)</dd>
      <dd> - Computer Graphics</dd>
      <dd> - Computer Networks</dd>
      <dd> - Computer Organization and Assembly Language</dd>
      <dd> - Database Application and Information Systems</dd>
      <dd> - Discrete Mathematics</dd>
      <dd> - Object Oriented Programming</dd>
      <dd> - Principles of Programming Languages</dd>
      <dd> - Programming Languages and Paradigms</dd>
      <dd> - Public Speaking</dd>
      <dd> - Python Programming I</dd>
      <dd> - Swift Design I</dd>
      <dd> - UNIX Operating Systems</dd>
      <dd> - Web Development I</dd>
    </dl>
  );
};

export default CourseBody;
