import React from "react";
import EventsLogo from "../../../assets/Bicentennial-Logo.png";
import "./EventsDescription.css";
//@ts-ignore
import Fade from "react-reveal/Fade";
import Button from "react-bootstrap/Button";

const EventsDescription: React.FC = () => {
  return (
    <Fade>
      <div className="events-container">
        <div className="logo">
          <img src={EventsLogo} alt="Events Logo" width={400} height={267} />
        </div>
        <div className="iframe-container">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/2_OaL7eQPSU?controls=0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
        <div className="events-description">
          <p>
            This is the Missouri Bicentennial Events App. This Application stack
            consists of three main elements, Angular, Ionic, and Firebase. This
            application is built for the Missouri Bicentennial Events Committee
            in order to spread more awareness for approved upcoming events
            throughout the state of Missouri. The Missouri Bicentennial Events
            App is currently in use, and the official site can be reached via
            the button below.
          </p>
          <p>
            This app was developed to run natively through Capacitor, and is
            available for download on the App Store Google Play Store.
          </p>
          <p>Visit the Github page for more information.</p>
        </div>
        <div className="buttons">
          <Button href="https://github.com/eventsapplication/EventApplication-Ionic">
            View On Github
          </Button>
          <Button
            href="https://missouri2021events.org/"
            className="button-space"
          >
            Visit Official Site
          </Button>
          <Button href="https://apps.apple.com/us/app/missouri-bicentennial-events/id1563147924">
            App Store
          </Button>
          <Button
            href="https://play.google.com/store/apps/details?id=com.streamlineai.bicentennialeventsapp&hl=en_US&gl=US"
            className="button-space"
          >
            Google Play Store
          </Button>
        </div>
      </div>
    </Fade>
  );
};
export default EventsDescription;
