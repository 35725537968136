import React from "react";
import "./ResumeBody.css";
import Resume from "../../../assets/Resume.png";
import Button from "react-bootstrap/Button";
// @ts-ignore
import ResumePDF from "../../../assets/RobertTruesdaleResume.pdf";

const ResumeBody: React.FC = () => {
  return (
    <>
      <div className="resume-img-container">
        <img src={Resume} alt={"resume"} className="resume-img" />
      </div>
      <div className="resume-btn-container">
        <Button variant="primary" href={ResumePDF}>
          Click to Download
        </Button>
      </div>
    </>
  );
};

export default ResumeBody;
