import React from "react";
import "./SkillsBody.css";

const SkillsBody: React.FC = () => {
  return (
    <div>
      <dl>
        <dt>Software Development Languages and Tools</dt>
        <dd>- C</dd>
        <dd>- C#</dd>
        <dd>- GitHub/ Git Bash</dd>
        <dd>- Object Oriented Languages, such as Go and Java</dd>
        <dd>- Python</dd>
        <dd>- SQL and DBMS such as MySQL, Postgres, and Oracle</dd>
        <dd>- Swift</dd>
        <dd>- Unity</dd>
        <dd>- Unix</dd>

        <dt>Web Development Languages and Tools</dt>
        <dd>- Classic HTML, CSS, and JavaScript</dd>
        <dd>- TypeScript</dd>
        <dd>- PHP</dd>
        <dd>- AJAX</dd>
        <dd>- Ionic Hybrid App Development</dd>
        <dd>- React Framework basic components</dd>
        <dd>- Angular</dd>
        <dd>- Node.js</dd>
        <dd>- Vue.js</dd>
        <dd>- API handling</dd>
        <dd>- AGILE Development Principles</dd>

        <dt>Code Editors/IDE's Used</dt>
        <dd>- Visual Studio/ VS Code</dd>
        <dd>- Unity/Unity Hub</dd>
        <dd>- IDLE</dd>
        <dd>- WebStorm</dd>
        <dd>- Brackets</dd>
        <dd>- Sublime</dd>
        <dd>- NetBeans</dd>
        <dd>- Xcode</dd>
        <dd>- Notepad ++</dd>
        <dd>- PyCharm</dd>
        <dd>- VMWare</dd>
        <dd>- IntelliJ</dd>
        <dd>- MySQLWorkbench</dd>
      </dl>
    </div>
  );
};

export default SkillsBody;
